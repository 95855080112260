<template>
	<div class="logDiv">
		<div class="logDiv_box">
			<div class="tubiao_box">
				<img src="../assets/logo.png" />
			</div>
			<!--表单区域-->
			<div class="login_from" style="min-height:25rem;">
				<el-tabs v-model="activeName" stretch>
					<el-tab-pane :label="$systemTitle" name="pwd">
						<template>
							<el-form  prop="loginForm" :model="loginForm"
								:rules="loginFormRules" ref="loginFormRef">
								<el-form-item prop="phone">
									<el-input v-model="loginForm.login_user" placeholder="请输入您的账号"></el-input>
								</el-form-item>
								<el-form-item prop="password">
									<el-input v-model="loginForm.login_pwd" placeholder="请输入您的密码" show-password>
									</el-input>
								</el-form-item>
								<el-form-item prop="codeText">
									<img :src="captchaUrl" @click="onGetCaptchaCode" style="
                      width: 160px;
                      height: 62px;
                      border: 1px solid #ccc;
                      border-radius: 5px;
                    " />
									<span style="color: red">点击图片刷新</span>
									<el-input v-model="loginForm.codeText" placeholder="请输入图形验证码"></el-input>
								</el-form-item>
							</el-form>
						</template>
					</el-tab-pane>

					<div class="myDiv">
						<el-button type="primary" style="width: 10rem;justify-content:center;" round @click="login" >登录</el-button>
					</div>

				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import md5 from "js-md5";

	export default {
		data() {
			return {
				btnTitle: "发送短信",
				isSendMsg: false,
				//登录页面数据绑定
				loginForm: {
          login_user: "",
          login_pwd: "",
					codeText: "", //图形验证码
					captchaCode: "", //输入的图片验证码

				},
				CaptchaData: null, //图形数据
				//tad 被激活的页签名称
				activeName: "pwd",
				captchaUrl: "", //图形验证码地址
				//表单验证规则对象
				loginFormRules: {
          login_user: [{
							required: true,
							message: "请输入账号",
							trigger: "blur"
						},
						{
							min: 2,
							max: 10,
							message: "长度在 2-10 个字符",
							trigger: "blur"
						},
					],

          login_pwd: [{
							required: true,
							message: "请输入登录密码",
							trigger: "blur"
						},
						{
							min: 2,
							max: 15,
							message: "长度在 2 到 15 个字符",
							trigger: "blur",
						},
					],
					codeText: [{
							required: true,
							message: "请输入图形验证码",
							trigger: "blur"
						},
						{
							min: 4,
							max: 4,
							message: "长度4个字符",
							trigger: "blur",
						},
					],

				},
			};
		},
		//生命周期函数
		created() {
			console.log("login onLoad");
			this.$callResetStore(); //清空登录信息

			this.onGetCaptchaCode();
		},

		methods: {
			//获取图形验证码
			async onGetCaptchaCode() {
				this.loginForm.codeText = ''
				//this.captchaUrl = this.$host + 'Verify/index?t=' + this.$getTimeStamp()
				const res = await this.$post("Verify/getCatpcha");
				console.log(res);
				if (res.code !== 200) {
					return;
				}
				//保存
				this.CaptchaData = res.data;
				//取出图片
				let imgsrc = this.CaptchaData.base64;
				console.log(imgsrc);

				this.captchaUrl = imgsrc;
				this.captchaCode = ""; //清空输入的验证码
			},
		login() {
				//validate()是回调函数 正确为true,错误为false
				this.$refs.loginFormRef.validate(async (valid) => {
					//console.log(valid);
					if (!valid) return;
					//判断登录模式   pwd｜code

						let code = md5(this.loginForm.codeText);
						console.log(code, this.CaptchaData.md5);
						if (code !== this.CaptchaData.md5) {
							this.$err("图形验证码错误");
							this.onGetCaptchaCode();
							return;
						}
           let obj = {
              login_pwd: this.loginForm.login_pwd,
              login_user: this.loginForm.login_user,
							captchakKey: this.CaptchaData.key,
							captchaCode: this.loginForm.codeText,
						};


					//发起登录网络请求
					const res = await this.$post("Login/login", obj);
					console.log(res);
					if (res.code !== 200) {
						if (this.activeName === "pwd") {
							await this.onGetCaptchaCode();
						}
            return
						// return this.$err(res.msg);
					}
          let userInfo=res.data || []
          this.$store.commit('setObj',{
            ...userInfo
          })
          //初始化菜单项
          this.$store.commit('setMyMenu',userInfo.powerDbList || [])

          await this.$router.push("/Home");

				});
			},
		},
	};
</script>



<style lang="less" scoped>
	.myDiv {
		width: 100%;
		display: flex;
		justify-content: center;
		align-content: center;
	}

	.logDiv {
		background-color: #2b4b6b;
		height: 100%;
	}

	.logDiv_box {
		width: 600px;
		background-color: #fff;
		border-radius: 4px;
		position: absolute; //居中对齐
		left: 50%;
		top: 56%;
		transform: translate(-50%, -50%); //位移自身一半
	}

	.tubiao_box {
		width: 150px;
		height: 150px;
		border: 1px solid #eee;
		border-radius: 50%;
		padding: 5px;
		box-shadow: 0 0 5px #ddd;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			background-color: #eee;
		}
	}

	.login_from {
		width: 50%;
		padding: 0 10px;
		margin: 20px auto;
		box-sizing: border-box;
	}

	.btns {
		display: flex;
		justify-content: flex-end;
	}

	.el-tabs {
		margin: 80px auto;
	}

	.el-input {
		margin-block-start: 10px;
		padding-right: 20px;
	}

	.el-button {
		margin-top: 10px;
		/*width: 100%;*/
		height: 40px;
	}

	.phoneBtn {
		display: flex;
		justify-content: space-between;
    align-items: center;
	}
</style>
