<template>
  <div class="margin-right-lg">
    <el-form :inline="true" class="searchDiv padding-left-sm">
      <el-form-item>
        <el-button type="danger" @click="onImportJob()">导入勾选</el-button>
      </el-form-item>
      <el-form-item label="职位ID" class="margin-left-lg">
        <el-input
            clearable
            placeholder="搜索职位ID"
            v-model="job_id"
        ></el-input>
      </el-form-item>

      <el-form-item label="职位名称">
        <el-input
            clearable
            placeholder="搜索职位名称"
            v-model="job_name"
        ></el-input>
      </el-form-item>

      <el-form-item label="用户ID">
        <el-input
            clearable
            placeholder="搜索用户ID"
            v-model="app_userid"
        ></el-input>
      </el-form-item>


      <!--      <el-form-item label="返佣类型">-->
      <!--        <el-select v-model="long_fan_type" clearable placeholder="请选择">-->
      <!--          <el-option-->
      <!--              v-for="(item2, index2) in ['一次性', '长线返']"-->
      <!--              :key="index2"-->
      <!--              :label="item2"-->
      <!--              :value="index2"-->
      <!--          >-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

      <el-form-item label="招聘状态">
        <el-select v-model="zhao_status" clearable placeholder="请选择">
          <el-option
              v-for="(item2, index2) in zhao_statusArr"
              :key="index2"
              :label="item2"
              :value="index2"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <!--      <el-form-item label="置顶状态">-->
      <!--        <el-select v-model="ad_order_status" clearable placeholder="请选择">-->
      <!--          <el-option-->
      <!--              v-for="(item2, index2) in ad_order_statusArr"-->
      <!--              :key="index2"-->
      <!--              :label="item2"-->
      <!--              :value="index2"-->
      <!--          >-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </el-form-item>-->

      <el-form-item class="margin-left-lg">
        <el-button type="primary" @click="getJobList()">查询</el-button>
      </el-form-item>

      <el-form-item class="margin-left-lg">
        <el-button type="warning" @click="onToGaoDe()">在线获取经纬度</el-button>
      </el-form-item>

    </el-form>


    <!--表格数据区-->
    <el-table
        ref="multipleTable"
        v-loading="isLoading"
        :height="getH()"
        :border="true"
        :data="tableData"
        highlight-current-row
        stripe
        :resizable="true"
        @selection-change="handleSelectionChange"
    >

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column
          v-for="(item, index) in propArr"
          :key="index"
          :prop="item.prop"
          :label="item.title"
          :min-width="item.width"
          :data-type="item.type"
          align="center"
          stripe
          show-overflow-tooltip
      >
        <template slot="header" slot-scope="headerData">
          <div :title="headerData.column.label">
            {{ getTitleLabel(headerData.column.label) }}
          </div>
        </template>
        <template slot-scope="scope">
          <div v-if="item.type == 'array'">
            {{ getOptionVal(item.data, scope.row[item.prop]) }}
          </div>
          <div v-else-if="item.prop == 'mapJobId'">
            {{scope.row['mapJobId'] && scope.row['mapJobId'].id}}
          </div>
          <div v-else class="titleDiv">
            {{ scope.row[item.prop] }}
          </div>
        </template>
      </el-table-column>

    </el-table>
    <!--底部分页区-->
    <el-pagination
        v-if="tableData.length > 0"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>

  </div>
</template>

<script>
export default {
  name: "ZhaoJobList",
  data() {
    return {
      //搜索
      job_id: "",
      app_userid: "37",
      job_name: "",
      long_fan_type: '',
      //表格字段
      propArr: [
        {
          prop: "mapJobId",
          title: "已导入ID",
          width: 150,
        },
        {
          prop: "job_id",
          title: "职位ID",
        },
        {
          prop: "job_name",
          title: "职位标题",
          width: 250,
          type: "textarea",
        },
        {
          prop: "job_address_text",
          title: "工作地址",
          width: 150,
          type: "textarea",
        },
        {
          prop: "job_address_lat",
          title: "工作地纬度",
          width: 150,
        },
        {
          prop: "job_address_lon",
          title: "工作地经度",
          width: 150,
        },
        {
          prop: "add_time",
          title: "添加时间",
          width: 180,
          type: "time",
        },

        {
          prop: "app_userid",
          title: "发布用户帐号ID",
          width: 150,
        },
        {
          prop: "userName",
          title: "发布用户昵称",
          width: 150,
        },
        {
          prop: "firm_fid",
          title: "公司信息ID",
          width: 150,
        },
        {
          prop: "firmName",
          title: "公司名称",
          width: 250,
        },
        {
          prop: "firm_tag",
          title: "公司标签",
          width: 150,
        },
        {
          prop: "is_dingxiang",
          title: "是否定向职位(0=否 1=是)",
          width: 150,
          type: "array",
          data: ["否", "是"],
        },
        {
          prop: "is_xunjia",
          title: "是否询价模式(0=否 1=是)",
          width: 150,
          type: "array",
          data: ["否", "是"],
        },
        {
          prop: "get_count",
          title: "访问次数",
          width: 150,
        },
        {
          prop: "check_status",
          title: "审核状态",
          width: 150,
          type: "array",
          data: ["待审核", "需交保证金", "已通过", "未通过"],
        },
        {
          prop: "check_msg",
          title: "审核说明",
          width: 150,
        },
        {
          prop: "check_userid",
          title: "审核帐号",
          width: 150,
        },
        {
          prop: "check_time",
          title: "审核时间",
          width: 180,
          type: "time",
        },
        {
          prop: "baozhengjin_num",
          title: "需交保证金额",
          width: 150,
        },
        {
          prop: "baozhengjin_status",
          title: "保证金状态",
          width: 150,
          type: "array",
          data: ["未交", "已交"],
        },
        {
          prop: "baozhengjin_baozhengid",
          title: "保证金表ID",
          width: 150,
        },
        {
          prop: "zhao_status",
          title: "招聘状态",
          width: 150,
          type: "array",
          data: ["正在招聘", "关闭", "已完成"],
        },
        {
          prop: "chengbao_status",
          title: "承包状态",
          width: 150,
          type: "array",
          data: ["不承包", "待承包", "已承包"],
        },
        {
          prop: "uptime",
          title: "更新时间",
          width: 180,
          type: "time",
        },

        {
          prop: "zhao_type",
          title: "招聘类型",
          width: 150,
        },
        {
          prop: "job_type",
          title: "职位类型",
          width: 150,
        },

        {
          prop: "join_company",
          title: "入职公司",
          width: 150,
          type: "textarea",
        },
        {
          prop: "job_address_city",
          title: "城市",
          width: 150,
        },

        {
          prop: "job_paylevel",
          title: "薪资待遇档次",
          width: 150,
        },
        {
          prop: "job_paytype",
          title: "工资类型",
          width: 150,
          type: "array",
          data: ["月薪", "年薪", "时薪", "其它"],
        },
        {
          prop: "job_minpay",
          title: "工资最低",
          width: 150,
        },
        {
          prop: "job_maxpay",
          title: "工资最高",
          width: 150,
        },
        {
          prop: "job_label",
          title: "职位标签(最多三个)",
          width: 150,
          type: "textarea",
        },
        {
          prop: "ad_order",
          title: "广告职位排序",
          width: 150,
        },
        {
          prop: "ad_img",
          title: "广告图片",
          width: 150,
        },
        {
          prop: "ad_text",
          title: "广告说明",
          width: 150,
          type: "textarea",
        },
        {
          prop: "ad_endtime",
          title: "广告结束时间",
          width: 150,
        },
        {
          prop: "need_num",
          title: "招聘人数",
          width: 150,
        },
        {
          prop: "need_education",
          title: "要求学历",
          width: 150,
        },
        {
          prop: "need_jingyan",
          title: "要求经验",
          width: 150,
        },
        {
          prop: "tui_fei",
          title: "推广费用",
          width: 150,
        },
        {
          prop: "tui_has_shui",
          title: "推广单价含税",
          width: 150,
          type: "array",
          data: ["不含", "含税"],
        },
        {
          prop: "join_day",
          title: "在职天数",
          width: 150,
        },
        {
          prop: "tui_vip",
          title: "推广VIP加价",
          width: 150,
        },
        {
          prop: "tui_svip",
          title: "推广超级VIP加价",
          width: 150,
        },
        {
          prop: "tui_allfei",
          title: "总推广费用",
          width: 150,
        },
        {
          prop: "tui_baotype",
          title: "承包类型",
          width: 150,
          type: "array",
          data: ["不支持", "分包", "整包"],
        },
        {
          prop: "tui_baonum",
          title: "分包数量",
          width: 150,
        },
        {
          prop: "tui_neednonum",
          title: "未完成不可承包次数(超多少次未完成的不能承包)",
          width: 150,
        },
        {
          prop: "chengbao_auid",
          title: "指定承包商ID",
          width: 150,
        },
        {
          prop: "firmhuozuo_id",
          title: "企业合作ID",
          width: 150,
        },
        {
          prop: "disable_comment",
          title: "禁止评论",
          width: 150,
          type: "array",
          data: ["否", "是"],
        },
        {
          prop: "bz",
          title: "备注",
          width: 150,
          type: "textarea",
        },
        {
          prop: "long_fan_type",
          title: "返佣类型",
          width: 150,
          type: "array",
          data: ["一次性", "长线返"],
        },
        {
          prop: "long_fan_day",
          title: "长线返佣天数",
          width: 150,
        },
        {
          prop: "long_compute_type",
          title: "计费类型",
          width: 150,
          type: "array",
          data: ["天", "小时"],
        },
        {
          prop: "long_day_hour",
          title: "每天多少小时",
          width: 150,
        },
        {
          prop: "long_jiesuan_day",
          title: "结算周期天数",
          width: 150,
        },
      ],
      /*
        `long_fan_type` int(11) NOT NULL DEFAULT '0' COMMENT '返佣类型(0=一次性 1=长线返)',
  `long_fan_day` int(11) NOT NULL DEFAULT '0' COMMENT '长线返佣天数',
  `long_compute_type` int(11) NOT NULL COMMENT '计费类型(0=天 1=小时)',
  `long_day_hour` int(11) NOT NULL DEFAULT '0' COMMENT '每天多少小时',
  `long_jiesuan_day` int(11) NOT NULL DEFAULT '0' COMMENT '结算周期天数',
  */
      //表格数据
      tableData: [],
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //审核状态 筛选
      check_status: "",
      //审核状态
      check_statusArr: ["待审核", "需交保证金", "已通过", "未通过"],
      //保证金状态
      baozhengjin_statusArr: ["未交", "已交"],
      //招聘状态(0=正在招聘 1=关闭 2=已完成)
      zhao_statusArr: ["正在招聘", "关闭", "已完成"],
      //承包状态
      chengbao_statusArr: ["不承包", "待承包", "已承包"],
      //工资类型
      job_paytypeArr: ["月薪", "年薪", "时薪", "其它"],
      //推广单价含税
      tui_has_shuiArr: ["不含", "含税"],
      //承包类型
      tui_baotypeArr: ["不支持", "分包", "整包"],
      //招聘状态
      zhao_status: 0,
      //置顶状态
      ad_order_status: "",
      ad_order_statusArr: ["未置顶", "已置顶"],

      boolArr: ["否", "是"],

      //正在加载
      isLoading: false,

      multipleSelection: []
    }
  },
  mounted() {
    //计算表格高度
    this.screenHeight = window.screenHeight = document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        this.screenHeight = window.screenHeight = document.body.clientHeight;
      })();
    };
  },
  computed: {
    maxHeight() {
      return this.screenHeight - this.otherHeight;
    },
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    console.log("joblist 创建");
    //this.initArr();
    this.getJobList();
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 470;
    },
    initArr() {
      //初始化选项值
      for (let i in this.propArr) {
        //是否定向职位\是否询价模式
        if (
            this.propArr[i]["prop"] == "is_dingxiang" ||
            this.propArr[i]["prop"] == "is_xunjia" ||
            this.propArr[i]["prop"] == "disable_comment"
        ) {
          this.propArr[i]["data"] = this.boolArr;
        }
        //审核状态
        if (this.propArr[i]["prop"] == "check_status") {
          this.propArr[i]["data"] = this.check_statusArr;
        }
        //保证金状态
        if (this.propArr[i]["prop"] == "baozhengjin_status") {
          this.propArr[i]["data"] = this.baozhengjin_statusArr;
        }
        //招聘状态
        if (this.propArr[i]["prop"] == "zhao_status") {
          this.propArr[i]["data"] = this.zhao_statusArr;
        }
        //承包状态
        if (this.propArr[i]["prop"] == "chengbao_status") {
          this.propArr[i]["data"] = this.chengbao_statusArr;
        }
        //工资类型
        if (this.propArr[i]["prop"] == "job_paytype") {
          this.propArr[i]["data"] = this.job_paytypeArr;
        }
        //推广单价含税
        if (this.propArr[i]["prop"] == "tui_has_shui") {
          this.propArr[i]["data"] = this.tui_has_shuiArr;
        }
        //承包类型
        if (this.propArr[i]["prop"] == "tui_baotype") {
          this.propArr[i]["data"] = this.tui_baotypeArr;
        }
      }
    },
    //获取表数据
    async getJobList(page = 1) {
      const postData = {
        job_id: this.job_id.trim(),
        app_userid: this.app_userid.trim(),
        job_name: this.job_name.trim(),
        // baozhengjin_status: this.baozhengjin_status,
        // check_status: this.check_status,
        page,
        pageSize: this.pageSize,
        zhao_status: this.zhao_status,
        ad_order_status: this.ad_order_status,
        long_fan_type: this.long_fan_type,
      };
      this.isLoading = true;
      const res = await this.$post("ZhaoJoblist/getList", postData);

      let ret = res.data || [];

      this.tableData = ret.data;
      this.page = ret.current_page;
      this.last_page = ret.last_page;
      this.total = ret.total;

      console.log(this.tableData);
      //职位标签转化

      for (let i in this.tableData) {
        this.tableData[i]["job_label"] = this.tableData[i]["job_label"]
            ? this.tableData[i]["job_label"].join(",")
            : "";
      }

      this.isLoading = false;
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getJobList(1);
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getJobList(this.page);
    },
    //取标题，有括号的去除
    getTitleLabel(val) {
      //console.log(val)
      if (!val) return "";
      let index = val.lastIndexOf("(");
      if (index > -1) {
        val = val.substring(0, index);
      }
      return val;
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //导入职位
    async onImportJob() {
      if (this.multipleSelection.length < 1) {
        this.$err('没有勾选职位')
        return
      }
      //弹出对话框
      const confirmResult = await this.$confirm(
          "确认导入勾选职位吗?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);

      if (confirmResult !== "confirm") {
        return this.$message.info("已取消导入");
      }
      //添加导入职位
      this.callImportJob()
    },
    //导入职位
    async callImportJob() {
      let arr = []
      for (let i in this.multipleSelection) {
        let item = this.multipleSelection[i]
        if(parseFloat(item.job_address_lat)<0.01
            || parseFloat(item.job_address_lon)<0.01){
          this.$err('工作地经纬度不能为0,职位ID:'+item.job_id)
          return
        }

        let show_pic = ''
        let show_video = ''
        for (let j in item.jobSwiper) {
          if (item.jobSwiper[j].type == 1) {
            show_video = item.jobSwiper[j].url
          } else if (show_pic.length < 1) {
            show_pic = item.jobSwiper[j].url
          }
        }

        arr.push({
          job_id: item.job_id,
          show_title: item.job_name,
          show_city: item.job_address_city,
          show_pic,
          show_video
        })
      }
      //批量导入
      const {code, msg, data} = await this.$post("MapJoblist/importJob", {
        arr
      });

      if (code !== 200) return
      this.$ok(msg)
    },
    //跳到高德API https://lbs.amap.com/tools/picker
    onToGaoDe(){
      window.open('https://lbs.amap.com/tools/picker')
    }

  }

}
</script>

<style scoped>
.searchDiv {
  display: flex;
  align-items: center;
}

.el-dialog__body {
  padding: 5px !important;
}
</style>