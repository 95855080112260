import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import md5 from 'js-md5'

//导入element ui
import './plugins/element.js'
//导入全局样式
import './assets/css/globe.css'
//导入字体图标

//import '../public/fonts/iconfont.css'

import axios from 'axios'
//导入插件
import TreeTable from 'vue-table-with-tree-grid'
//配置路径

Vue.prototype.$http = axios

//API服务器地址
// Vue.prototype.$ip = "http://127.0.0.1:9091/"
Vue.prototype.$ip = "https://mapapi.zt.work/"

Vue.prototype.$baseUrl = Vue.prototype.$ip + "api.php/"
Vue.prototype.$imgUrl = Vue.prototype.$ip + "/storage/"

//上传服务器交互地址--COS上传
Vue.prototype.$serverUrl = Vue.prototype.$baseUrl + "Cos/getCode"

//系统标题
Vue.prototype.$systemTitle = "助他招聘地图PC端"

document.title = Vue.prototype.$systemTitle
//storage
Vue.use(ElementUI);

//导入store
import {store, getNewStore} from '@/store/index.js'
//重置Store
Vue.prototype.$callResetStore = function () {
    let newStore = getNewStore(store)
    //重新赋值，触发保存
    store.commit('setObj', newStore)
    console.log("已重置Store")
}


//引入 base64

import {Base64} from "js-base64"

Vue.prototype.base64EnCode = Base64.encode

// 把字符串中的汉字、非字母数字 s转换成Unicode
function ch2Unicdoe(str) {
    if (!str) {
        return;
    }
    var unicode = '';
    for (var i = 0; i < str.length; i++) {
        var temp = str.charAt(i);
        if (isChinese(temp)) {
            unicode += '\\u' + temp.charCodeAt(0).toString(16);
            //console.log(temp, '\\u' + temp.charCodeAt(0).toString(16));
        } else if (temp.charCodeAt(0) > 127) {
            //210912 新增判断是否为扩展的ansi码 如 °
            unicode += '\\u00' + temp.charCodeAt(0).toString(16);
        } else {
            unicode += temp;
        }
    }
    return unicode;
}

//获取用户信息
function getUserData() {
    let retObj = {
        userid: store.state.auid ? store.state.auid : 0,
        token: store.state.token ? store.state.token : ''
    }
    return retObj
}

Vue.prototype.$getUserData = getUserData;

//全局显示HTML
Vue.prototype.$showHtml = function (html) {
    this.$alert(html, '查看', {
        dangerouslyUseHTMLString: true
    });
}
//引入JSON编辑器
import CodeEditor from 'bin-code-editor';

Vue.use(CodeEditor);

// 判断字符是否为汉字，
function isChinese(s) {
    //return /[\u4e00-\u9fa5]/.test(s); //判断字符是否为汉字，
    //[^\x00-\xff] //匹配双字节字符 包括汉字在内：[^\x00-\xff]
    // eslint-disable-next-line no-control-regex
    return /[^\x00-\xff]/.test(s);
}

const signTxt = "jwiealcosaekwnnkvnaflfo;o;rel" //加密

/*获取发送参数*/
function getSendParam(data = [], isFile = false) {
    //计算加密
    let time = Date.parse(new Date()) / 1000;

    let arr = [];
    //let datajson = data ? JSON.stringify(data):[];
    arr['data'] = data && isFile == false ? data : [];
    let str = ch2Unicdoe(JSON.stringify(arr['data']));
    //把/改为 \/
    let newstr = str.replace(/\//g, '\\/');
    //console.log(newstr);
    let sign = md5(newstr + '&' + time + '&' + signTxt);
    //转为大写
    sign = sign.toLocaleUpperCase()
    console.log(newstr + '&' + time + '&' + signTxt);
    arr['sign'] = sign;
    arr['time'] = time;
    let obj = getUserData()
    arr['userid'] = obj.userid;
    arr['token'] = obj.token;
    //还原data
    arr['data'] = data
    console.log(arr);
    return arr;
}

Vue.prototype.$getSendParam = getSendParam

//封装POST请求
Vue.prototype.$post = function (method, data = [], file = null, fileName = 'file') {
    console.log("封装POST请求", data)
    const isRet = false;
    // eslint-disable-next-line no-unused-vars
    let isShowDlg = false;

    //1秒后才显示
    setTimeout(function () {
        //1秒未返回才弹出
        if (isRet === false) {
            isShowDlg = true
            //为了用户体验，加一个loading效果

        }

    }, 1000)


    let arr = getSendParam(data, !!file)

    let url = this.$baseUrl + method
    return new Promise((resolved, rejected) => {
        console.log(url, arr)
        let obj
        //配置能上传文件
        if (file) {
            let params = new FormData();
            for (let i in arr) {
                //如果放在data下的，展开
                if (i == 'data') {
                    for (let j in arr[i]) {
                        params.append(j, arr[i][j])
                    }
                } else {
                    params.append(i, arr[i])
                }

            }
            params.append(fileName, file);
            obj = {
                method: 'POST',
                url,
                data: params,
                headers: {'Content-Type': 'multipart/form-data'},
            }
        } else {
            obj = {
                method: 'POST',
                url,
                data: {
                    ...arr
                }
            }
        }
        this.$http(obj).then(res => {
            //console.log("返回数据",res)
            //console.log("statusCode",res.status)
            if (res.status === 200) {
                //判断是否为200业务成功代码
                if (res.data.code !== 200) {
                    console.log("res", res)
                    this.$err(res.data.msg)
                    //检测是否需要登录
                    if (res.data.code === 300) {
                        console.log(res.data.msg)
                        //跳到登录页
                        this.$router.push('/login')
                    }

                }
                //返回执行数据
                resolved(res.data);
                return
            }
            //出错的代码
            rejected(res)
            return
        }).catch(error => {
            rejected(error)
        })


    })
}
//请求当前拦截
axios.interceptors.request.use(config => {
    console.log(config)
    return config
})

Vue.component('tree-table', TreeTable)

const getData = function (originVai) {
    console.log('getData', originVai)
    //const dt= new Date(parseInt(originVai) * 1000)
    const dt = new Date(parseInt(originVai + '000'))
    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')

    const hh = (dt.getHours() + '').padStart(2, '0')
    const mm = (dt.getMinutes() + '').padStart(2, '0')
    const ss = (dt.getSeconds() + '').padStart(2, '0')
    let ret = `${y}-${m}-${d}  ${hh}:${mm}:${ss}`
    // console.log(originVai,ret)
    return ret
}
//全局时间挂在
Vue.filter("dateFormat", getData)
//时间处理挂载
Vue.prototype.$getData = getData
/**
 * 获取短日期 2023-8-8 改为 08-08
 */
Vue.prototype.$getShortData = function (originVai){
    console.log('$getShortData', originVai)
    const dt = new Date(originVai )
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')
    const d = (dt.getDate() + '').padStart(2, '0')

    let ret = `${m}-${d}`
    return ret
}

/**
 * 获取年月 2023-8
 */
Vue.prototype.$getMonth = function (){
    const dt = new Date( )

    const y = dt.getFullYear()
    const m = (dt.getMonth() + 1 + '').padStart(2, '0')

    let ret = `${y}-${m}`
    console.log('获取年月',ret)
    return ret
}

//字符串转10位时间戳
Vue.prototype.$getTime = function (str) {
    //var date="2014-05-08 00:22:11";
    let date = new Date(Date.parse(str.replace(/-/g, "/")));
    date = date.getTime() / 1000;
    return date
}
//获取今天字符串或几天前几天后
Vue.prototype.$getToday = function (days = 0) {
    let date= new Date();
    var d = new Date(date.getTime() + days * 24 * 60 * 60 * 1000);
    var year = d.getFullYear();
    var month = change(d.getMonth() + 1);
    var day = change(d.getDate());
    // var hour = change(d.getHours());
    // var minute = change(d.getMinutes());
    // var second = change(d.getSeconds());

    function change(t) {
        if (t < 10) {
            return "0" + t;
        } else {
            return t;
        }
    }

    var time = year + '-' + month + '-' + day
    //+ ' '+ hour + ':' + minute + ':' + second;

    return time;
}
Vue.prototype.$copy = function (val = '') {
    //var Url2 = document.getElementById("biao1").innerText;
    var oInput = document.createElement('input');
    oInput.value = val;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    this.$ok('复制成功');
}


//补全图片或下载地址
Vue.prototype.$getUrl = function (url) {
    //console.log(url);
    if (!url || url.length < 1) return;
    if (url.indexOf("http") != 0) {
        url = this.$imgUrl + url;
    }
    return url;
}

//获取选项值
Vue.prototype.getOptionVal = function (arr = [], val, type = "array") {
    // console.log(val, arr, type);
    // if (!arr || arr.length < 1) return "";
    if (type == "arrayObj") {
        for (let i in arr) {
            if (arr[i].val == val) {
                //console.log("找到", val, arr[i].title);
                return arr[i].title;
            }
        }
        //console.log(val, "未找到");
        return "";
    } else {
        return arr[val];
    }
}

//获取选项值 --json
Vue.prototype.getJsonVal = function (val, isTxt = false) {
    //console.log("处理JSON",val)
    if (!val) return val;
    //console.log("JSON.pars:",val)
    let arr = JSON.parse(val)
    //console.log("得到arr:",arr)
    let str = ''
    for (let i in arr) {
        str += i + ':' + arr[i] + (isTxt == true ? "\n" : '<br/>')
    }
    //console.log("得到文本:"+str)
    return str;

}
//判断是否为开发环境
Vue.prototype.$isDev = function () {
    if (process.env.NODE_ENV === "development") {
        console.log("开发环境");
        return true;
    } else {
        console.log("生产环境");
        return false;
    }
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
