<template>
  <div>
    <div class="show-city cu-tag  radius margin-top-lg margin-left" v-show="show_city" :class="has_job==true?'bg-gradual-pink':'bg-gray'">
      {{ show_city }}
    </div>
    <!-- 点标记区-->
    <div style="display: none;">
      <div :ref="'ref_'+index+''" :id="'markerDiv_'+index+''" v-for="(item,index) in positionJobArr" :key="index"
           @click.stop="(e)=>{
             return onMarkerClick(e,index)
           }"
           @touchstart.stop="(e)=>{onMarkerClick(e,index)}"
      >
        <div class="only-pc flex justify-center">
          <!--  marker-animation        <img style="width: 100%;"  :src="animation_pic" />-->
          <el-image
              style="height: 75px"
              :src="getAutoPic()"
              fit="cover"></el-image>
        </div>
        <div class="marker">
          <div class="marker-title only-pc" v-if="is_simple==false">
            <div class="marker-title-empty"></div>
            <div class="marker-title-content">{{
                getMarkerTitle(item.data[item.selIndex])
              }}
            </div>
            <img class="type-img" v-if="item.data[item.selIndex].show_video" :src="typeVideoPic"/>
            <img class="type-img" v-else-if="item.data[item.selIndex].show_pic" :src="typeImgPic"/>
          </div>
          <!-- 显示的简介内容-->
          <div class="marker-text"
               :style="is_simple==false?'margin-top: 5px;':''"
               :id="'markerText_'+index">[{{ item.data[item.selIndex].job_id }}]
            {{ item.data[item.selIndex].show_title }}
          </div>

          <!--职位选择器-->
          <div v-if="item.data.length>1" class="jobid-list">
            <!--            <div v-for="(jobInfo,i2) in item.data" :key="i2">{{jobInfo.job_id}}</div>-->
            <div v-for="(jobInfo,i2) in item.data" :key="i2"
                 @click.stop="(e)=>{onChangeJob(index,i2,e)}"
                 @touchstart.stop="(e)=>{onChangeJob(index,i2,e)}"
                 style="z-index: 999;">
              <el-button size="mini" class="margin-left-xs"
                         :id="'btn_'+jobInfo.job_id"
                         round
                         style="margin-top: 4px;"
                         :type="item.selIndex==i2?'danger':'info'">
                {{ jobInfo.job_id }}
              </el-button>
            </div>
          </div>
        </div>

        <!--旗帜-->
        <div class="only-pc flex justify-center" style="position: relative;">
          <template>
            <el-image
                style="height: 75px"
                :src="item.data[item.selIndex].qizhi"
                fit="cover"></el-image>
            <div class="qizhi-word" :style="item.data[item.selIndex].style">{{ set_json.qizhi_word }}</div>
          </template>
        </div>


      </div>
    </div>
    <!--信息窗体-->
    <div style="display: none;">
      <div ref="ref_infowindow" id="info" v-if="selKey">
        <!--图片视频区 style="width: 100%;height: auto;"-->
        <div class="info-img" v-if="isShowInfoWindow==true">
          <video v-if="positionJobArr[selKey].data[positionJobArr[selKey].selIndex].show_video" controls="controls"
                 autoplay
                 style="width: 100%;height: auto;max-height: 50vh;"
                 :src="positionJobArr[selKey].data[positionJobArr[selKey].selIndex].show_video">
            您的浏览器不支持 video 视频。
          </video>
          <img v-else-if="positionJobArr[selKey].data[positionJobArr[selKey].selIndex].show_pic"
               style="width: 100%;height: auto;"
               :src="positionJobArr[selKey].data[positionJobArr[selKey].selIndex].show_pic"/>
        </div>
        <!--文字区-->
        <div class="info-div">
          <!--{{positionJobArr[selKey].selIndex}}   -->
          <div class="margin-top-xs padding-xs bg-orange flex justify-between radius" style="cursor: pointer;"
               onclick="onToJob()">
            <div>[{{ positionJobArr[selKey].data[positionJobArr[selKey].selIndex].job_id }}]
              {{ positionJobArr[selKey].data[positionJobArr[selKey].selIndex].show_title }}
            </div>
            <div class="text-sm">{{ positionJobArr[selKey].data[positionJobArr[selKey].selIndex].show_city }}</div>
          </div>
          <div class="info-content">
            <div v-for="(itemDesc,indexDesc) in positionJobArr[selKey].data[positionJobArr[selKey].selIndex].jobDesc"
                 :key="indexDesc"
                 class="padding-bottom-xs">
              <div class=" text-black flex justify-start align-center">
                <div class="my-round"></div>
                {{ itemDesc.item_title }}
              </div>
              <div class="text-black text-sm" style="text-indent: 2em;">{{ itemDesc.item_val }}</div>
            </div>
          </div>

        </div>


      </div>
    </div>

    <!--    <div>-->
    <!--      当前坐标:{{ lnglat }}-->
    <!--    </div>-->
    <!-- 地图区-->
    <div class="mapContainer" id="map">
      <!--显示标题-->
      <div class="map-title only-pc" v-if="set_json.map_title">
        {{ set_json.map_title }}
      </div>
      <!--搜索框-->
      <div class="only-pc search-div margin-top-lg">
        <div class="flex align-center justify-between  bg-grey padding-xs radius">
          <div class="">搜索地图：</div>
          <div class="flex-sub">
            <el-input
                id="tipinput"
                size="small"
                placeholder="请输入关键字"
                v-model="searchKey"
                clearable>
            </el-input>
          </div>
          <div class="margin-left-xs">
            <!--            :disabled="searchKey.length<1"-->
            <el-button size="mini" type="success" id="searchBtn" @click="onSearchKey()">
              搜索
            </el-button>
          </div>
        </div>

        <!--搜索结果列表-->
        <div id="panel" v-show="isShowPanel"></div>
      </div>

    </div>

  </div>

</template>

<script>
import {mapState} from "vuex"; // 引入mapState

import AMapLoader from '@amap/amap-jsapi-loader'
//用于通信
import * as uni from "@/utils/uni.webview.1.5.2"
var myUni = null
document.addEventListener("UniAppJSBridgeReady", function() {
  myUni = uni
  console.log('myUni 初始化',myUni)
});

window._AMapSecurityConfig = {
  securityJsCode: '490e7d54aef3e63dc9d072fd3fc0a0dd',
}
var AMap = null

var map = null
//全局保存的信息窗体索引
// var winSelInfoIndex = -1
//全局保存的信息窗体对象
var winSelInfoItem = null
//搜索组件
var placeSearch = null
//信息窗体
var infoWindow = null

var is_simple = false
//跳转到职位详情事件
window.onToJob = function (index) {
  console.log(winSelInfoItem)
  console.log(winSelInfoItem.data[winSelInfoItem.selIndex].job_id)
  let url = 'https://h5.zt.work/#/pages/zhaoping/jobinfo?jobid=' + winSelInfoItem.data[winSelInfoItem.selIndex].job_id
  window.open(url)
}

export default {
  data() {
    return {
      typeImgPic: require("@/assets/pic.png"),
      typeVideoPic: require("@/assets/video.png"),
      // map: null,
      lnglat: '',
      //当前选中索引
      selInfoIndex: -1,
      //数据列表
      dataList: [],
      //是否已初始化完成
      isInit: false,
      timeOutID: 0,

      searchKey: '',
      //显示城市
      show_city: '',
      //当前显示用户ID
      show_userid: 0,
      //用户设置对象
      set_json: {
        'map_title': '',//'地图标题',
        'marker_title': '', //'标记标题',
        'show_city': '', //显示城市
        'qizhi_word': '',//旗帜显示的字
      },
      //已添加的标记对象
      markerIdObj: {},

      isShowPanel: false,
      isSetCity: false,
      //是否首次获取
      is_first: true,
      //城市列表
      city_list: [],
      //是否有职位
      has_job: false,
      // //动图网址
      // animationStyle: {},
      // //动图
      // animation_pic: '',
      // //旗帜
      // qizhi_pic: '',
      //图片列表
      qizhi_pic_list: [],
      auto_pic_list: [],
      //旗帜写字的位置
      qizhi_pic_word: [],

      //地图位置上的职位ID对象
      positionJobArr: {},
      selKey: null,
      isShowInfoWindow: false,

      //-------------------------以下用于助他内部调用
      //是否简单模式，点击时发送消息
      is_simple: false,
      //初始初始城市
      init_city:null,

    }
  },
  computed: {
    ...mapState(['login_user', 'auid']),
  },
  created() {
    let id = this.$route.query.id
    if (id) {
      this.show_userid = atob(id)
    }
    //增加助他简单模式
    let from = this.$route.query.from
    if (from == 'zuta') {
      this.is_simple = true
      is_simple = true
    }
    let city  = this.$route.query.city
    if (this.is_simple && city) {
      this.init_city =city
    }
    console.log("show_userid", this.show_userid)
  },
  mounted() {
    let load = AMapLoader.load({
      key: "3a053f237860e95a6191ebe2e320d06c",             // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: ['AMap.Scale', 'AMap.HawkEye', 'AMap.ToolBar', 'AMap.ControlBar', 'AMap.InfoWindow', 'AMap.AdvancedInfoWindow'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((m) => {
      AMap = m

      this.initMap();
      //获取地图数据
      this.getMapData()

    }).catch(e => {
      console.log(e);
    })
    console.log(load)
  },
  methods: {
    //获取动图
    getAutoPic() {
      if (this.auto_pic_list.length < 1) return ''
      let r = Math.round(Math.random() * (this.auto_pic_list.length - 1) + 1) - 1
      // console.log('getAutoPic', r, this.auto_pic_list.length, this.auto_pic_list[r])
      return this.$getUrl(this.auto_pic_list[r])
    },
    //获取旗帜图
    getQizhiPic() {
      if (this.qizhi_pic_list.length < 1) return ''
      let r = Math.round(Math.random() * (this.qizhi_pic_list.length - 1) + 1) - 1
      console.log('getQizhiPic', r, this.qizhi_pic_list.length, this.qizhi_pic_list[r])
      return this.qizhi_pic_list[r]
    },
    //获取职位数据
    async getMapData() {
      if (this.is_first == false && this.show_city.length > 0) {
        //是否有数据，没有就不用请求了
        let index = this.city_list.findIndex((item) => {
          return item.indexOf(this.show_city) > -1 ? true : false
        })
        if (index == -1) {
          console.log("没有数据不用请求", this.show_city)
          this.has_job = false
          return
        } else {
          console.log("有数据需要请求", this.show_city)
          this.has_job = true
        }
      }

      let obj = {
        // page: this.pageNum,
        // pageSize: this.pageSize,
        is_simple: this.is_simple == true ? 1 : 0, //是否简单模式
        show_city: this.show_city,
        show_userid: this.show_userid,
        is_first: this.is_first == true ? 1 : 0
      };
      const {code, msg, data} = await this.$post(`MapView/getData`, obj);
      console.log(msg);
      if (code !== 200) return;

      this.set_json = data.set_json ? data.set_json : this.set_json
      console.log('this.set_json', this.set_json)
      this.show_city = data.show_city

      if (this.is_first == true) {
        this.is_first = false
        this.city_list = data.city_list
        //获取图片列表
        this.qizhi_pic_list = data.qizhi_pic_list
        this.auto_pic_list = data.auto_pic_list
        this.qizhi_pic_word = data.qizhi_pic_word
        // //旗帜
        // this.qizhi_pic=this.$getUrl(data.qizhi_pic)
        // //动图随机获取的
        // this.animation_pic=this.$getUrl(data.animation_pic)
        // this.animationStyle={
        //   backgroundImage: 'url(' +this.animation_pic+ ")"
        // }

        if (this.isSetCity == false) {
          this.isSetCity = true
          console.log("设置中心区", this.show_city)
          map.setCity(this.show_city);
        }

      }

      //合并内容
      let list = data.list.data
      console.log("字位置列表", this.qizhi_pic_word)
      for (let i in list) {
        let id = list[i]['id']
        let index = this.dataList.findIndex((item) => {
          return item.id == id
        })
        if (index == -1) {
          //添加旗帜图与字位置
          let fileName = this.getQizhiPic()
          list[i]['qizhi'] = this.$getUrl(fileName)
          //得到旗帜的文件名
          let f = fileName.replace('/qizhi/', '')

          //添加字位置
          list[i]['style'] = this.qizhi_pic_word[f]
          console.log('添加旗帜图与字位置', list[i]['qizhi'], f, list[i]['style'])

          this.dataList.push(list[i])
        }
      }

      console.log('this.dataList', this.dataList)


      this.$nextTick(() => {
        this.initPositionJobArr()
        this.initMarker()
      })

    },
    //初始化地图
    initMap() {
      let that = this

      console.log("开始初始化地图",this.init_city)
      map = new AMap.Map("map", {
        // resizeEnable: true, //是否监控地图容器尺寸变化
        // center: this.dataList[0].lnglats,// [106.59365, 29.662659],//地图中心点，按照自己要求设置即可

        zoom: 13,//地图缩放级别
        zooms: [2, 20],// [8, 13],
        // showLabel: false,//是否展示地图文字和 POI 信息。
        // layers: [
        //   // 卫星
        //   new AMap.TileLayer.Satellite(),
        //   // 路网
        //   new AMap.TileLayer.RoadNet()
        // ],

      })
      // map.setCity(that.show_city);
      // if (this.isSetCity == false) {
      //   this.isSetCity = true
      //   console.log("设置中心区", this.show_city)
      //   map.setCity(this.show_city);
      // }
      // console.log("设置中心区", this.show_city)
      // map.setCity(this.show_city);
      // this.isSetCity = true

      if (this.is_simple==true && this.isSetCity == false && this.init_city) {
        this.isSetCity = true
        this.show_city=this.init_city
        console.log("设置初始初始城市", this.init_city)
        map.setCity(this.init_city);
      }
      //绑定移动事件
      map.on('mapmove', logMapinfo);

      //获取并展示当前城市信息
      function logMapinfo() {
        // console.log("获取并展示当前城市信息")
        if (that.isInit == true) {
          // console.log("isInit", that.isInit)
          map.getCity(function (info) {
            let district = info.district || info.city
            if (that.show_city !== district) {
              console.log("show_city=" + that.show_city + ",district=" + district)
              that.show_city = district
              if (that.timeOutID > 0) {
                clearTimeout(that.timeOutID)
                console.log("clearTimeout", that.timeOutID)
              }

              that.timeOutID = setTimeout(() => {
                console.log("获取并展示当前城市信息")
                that.getMapData()
              }, 500)
            }
          });
        }
      }


      map.setDefaultCursor("pointer"); // 使用CSS默认样式定义地图上的鼠标样式

      //缩放工具
      AMap.plugin('AMap.ToolBar', () => {
        let toolBar = new AMap.ToolBar({
          visible: true,
          // position: {
          //   top: '110px',
          //   right: '40px'
          // }
          position: {
            top: '50px',
            left: '20px'
          }
        })
        map.addControl(toolBar);
      });


      // let scale = new AMap.Scale({
      //   visible: false
      // })

      AMap.plugin('AMap.Scale', () => {
        let scale = new AMap.Scale({
          visible: true,

        })
        map.addControl(scale);
      });

      // let controlBar = new AMap.ControlBar({
      //   visible: false,
      //   position: {
      //     top: '10px',
      //     right: '10px'
      //   }
      // })
      //旋转工具
      AMap.plugin('AMap.ControlBar', () => {
        let controlBar = new AMap.ControlBar({
          visible: true,
          position: {
            top: '10px',
            right: '10px'
          }
        })
        // map.addControl(controlBar);
      });


      // let overView = new AMap.HawkEye({
      //   visible: true
      // })

      AMap.plugin('AMap.HawkEye', () => {
        let overView = new AMap.HawkEye({
          visible: true,
        })
        map.addControl(overView);
      });


      // 构造官方卫星、路网图层
      let satelliteLayer = new AMap.TileLayer.Satellite();
      map.add([satelliteLayer]);
      let roadNetLayer = new AMap.TileLayer.RoadNet();
      //批量添加图层
      map.add([roadNetLayer]);

      //为地图注册click事件获取鼠标点击出的经纬度坐标

      // map.on('click', function (e) {
      //   that.lnglat = e.lnglat.getLng() + ',' + e.lnglat.getLat()
      //   console.log("当前坐标:" + that.lnglat)
      // });


      //输入提示
      var autoOptions = {
        input: "tipinput"
      };

      AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], function () {
        /*
        var auto = new AMap.AutoComplete(autoOptions);
        var placeSearch = new AMap.PlaceSearch({
          map: map
        });  //构造地点查询类
        auto.on("select", select);//注册监听，当选中某条记录时会触发
        function select(e) {
          placeSearch.setCity(e.poi.adcode);
          placeSearch.search(e.poi.name);  //关键字查询查询
        }
        */
        //构造地点查询类
        placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          // city: "010", // 兴趣点城市
          // citylimit: true,  //是否强制限制在设置的城市内搜索
          map: map, // 展现结果的地图实例
          panel: "panel", // 结果列表将在此容器中进行展示。
          autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });

      });
    },
    //初始化标记对象组 ，以坐标为准
    initPositionJobArr() {
      let dataList = this.dataList;
      for (let i = 0; i < dataList.length; i++) {
        let item = dataList[i]
        let job_id = item['job_id']

        let position = [
          item['jobInfo']['job_address_lon'],
          item['jobInfo']['job_address_lat']
        ]
        let key = position.join('_')
        if (!this.positionJobArr[key]) {
          this.positionJobArr[key] = {
            selIndex: 0,
            data: [],
          }
        }
        //不存在的才把对象放入
        let index = this.positionJobArr[key].data.findIndex((item) => {
          return item.job_id == job_id
        })
        if (index == -1) {
          this.positionJobArr[key].data.push({
            ...item
          })
        }


      }
      console.log("当前坐标上的对象组")
      console.log(this.positionJobArr)
    },
    //初始化标记
    initMarker() {
      console.log("开始初始化标记")
      if (this.dataList.length < 1) {
        console.log("没有内容显示")
        this.isInit = true
        return
      }

      let that = this
      this.isInit = false
      //给多个点添加信息窗体
      var dataList = this.dataList;

      if (!infoWindow) {
        infoWindow = new AMap.InfoWindow({
          // isCustom:true, //是否自定义窗体
          // offset: new AMap.Pixel(0, -30),
          anchor: 'center', //信息窗体锚点。默认值：'bottom-center'。
          // 可选值：'top-left'|'top-center'|'top-right'|'middle-left'|'center'|'middle-right'|'bottom-left'|'bottom-center'|'bottom-right'
          autoMove: true, //是否自动调整窗体到视野内
          closeWhenClickMap: true,//鼠标点击地图后关闭信息窗体
        });
        //关闭信息窗体时销毁
        infoWindow.on("close", () => this.isShowInfoWindow = false)
      }

      let positions = []
      for (var i = 0, marker; i < dataList.length; i++) {

        let item = dataList[i]
        let job_id = item['job_id']

        console.log(job_id, item)


        let position = [
          item['jobInfo']['job_address_lon'],
          item['jobInfo']['job_address_lat']
        ]

        // let content = '我是第' + (i + 1) + '个Marker'
        let key = position.join('_')

        if (this.markerIdObj.hasOwnProperty(key)) {
          console.log("此职位标记已存在:" + key, this.markerIdObj[key])
          continue
        }

        positions.push(
            position
        )
        console.log('ref_' + key, this.$refs['ref_' + key])
        let htmlContent = this.$refs['ref_' + key] ? this.$refs['ref_' + key][0] : null
        if (htmlContent) {
          console.log(htmlContent, position)
          var marker = new AMap.Marker({
            position: position,
            map: map,
            content: htmlContent,
            offset: new AMap.Pixel(-113, -240),  //设置标注偏移量
            extData: key //保存坐标值
          });
          //添加点击事件
          //marker.on('click', markerClick);

          // marker.on('click', (e) => {
          //   console.log("点击了", e)
          //   if(this.isShowInfoWindow==true){
          //     this.onMarkerClick(e, key)
          //   }
          // });
          // marker.on('touchstart',(e)=>{
          //   console.log("移动端 touchstart", e)
          //   this.onMarkerClick(e, key)
          // })
          //按职位ID与标记对象保存为键值对

          this.markerIdObj[key] = marker
        } else {
          console.log("此标记内容不存在", 'ref_' + key)
        }

      }

      //自动缩放地图到合适的视野级别
      // map.setFitView();

      //1.把想自适应的部分的点装在一个透明的覆盖物图层里
      var polygon = new AMap.Polygon({
        path: positions,  //以5个点的坐标创建一个隐藏的多边形
        map: map,
        strokeOpacity: 0,//透明
        fillOpacity: 0,//透明
        bubble: true//事件穿透到地图
      });
      var overlaysList = map.getAllOverlays('polygon');//获取多边形图层

      //2.使用setFitView方法
      // map.setFitView(overlaysList);//自适应显示

      this.isInit = true
    },
    // 搜索关键字
    onSearchKey() {
      console.log("onSearchKey", this)
      let key = this.searchKey//document.getElementById("tipinput").value
      if (!key || key.length < 1) {
        this.isShowPanel = false
      }
      this.$nextTick(() => {
        this.searchKey = ''
        this.$forceUpdate()
        console.log('key', key, this.searchKey)
      })
      if (placeSearch && key) {
        placeSearch.search(key)
        this.isShowPanel = true
      }
    },
    //获取标记标题
    getMarkerTitle(item) {
      //自定义模板标题
      let t = this.set_json.marker_title || '[地区]'
      //地区变量 [地区]
      let show_city = item.show_city
      let ret = t.replace('[地区]', show_city)
      return ret
    },
    // 点标记事件
    onMarkerClick(e, selKey) {
      e.preventDefault();
      console.log("onMarkerClick", e, selKey)
      if (!selKey) return
      //读取坐标key
      this.selKey = selKey
      console.log("selKey", this.selKey)
      // winSelInfoIndex = that.selInfoIndex
      winSelInfoItem = this.positionJobArr[this.selKey]
      console.log("winSelInfoItem", winSelInfoItem)

      if (this.is_simple == true) {
        console.log("myUni", myUni)
        //简单模式，直接发送消息 传递职位ID
        let data = {
          job_id: winSelInfoItem.data[winSelInfoItem.selIndex].job_id
        }
        //用于框架是APP
        myUni.postMessage({
          data:JSON.stringify(data)
        });
        //用于框架是H5
        window.top.postMessage(JSON.stringify(data),'*')
        console.log("发送消息", data, myUni)
        return
      }
      // let infoHtml = document.getElementById('ref_infowindow')
      this.isShowInfoWindow = true
      this.$nextTick(() => {
        let infoHtml = this.$refs['ref_infowindow']
        infoWindow.setContent(infoHtml);

        infoWindow.open(map, map.getCenter());// e.target.getPosition());
      })

    },
    //切换选中的职位ID
    onChangeJob(key, index2, e) {
      e.preventDefault();
      this.isShowInfoWindow = false
      this.$set(this.positionJobArr[key], 'selIndex', index2)
      // this.positionJobArr[key].selIndex = index2
      console.log("切换选中的职位ID", e, key, this.positionJobArr[key])
      this.$forceUpdate()

      return

    }

  },

}
</script>

<style>


.mapContainer {
  /*width: 98vw;*/
  height: 100vh;
  /*position: relative;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-title {
  position: absolute;
  /*left: 50%;*/
  top: 10px;
  /*margin: 10px 0;*/
  z-index: 999;
  height: 55px;
  background-color: rgba(255, 0, 0, 0.5);
  padding: 15px 35px;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  /*opacity: 0.2;*/
}

@media all and (max-width: 901px) {
  .only-pc {
    display: none;
  }

  .marker {
    width: 35vw !important;
    font-size: 12px !important;
    padding: 4px 3px !important;
  }

  .type-img {
    width: 12px !important;
    height: 12px !important;
  }
}

.marker {
  min-width: 200px;
  position: relative;
  padding: 10px 12px;
  font-size: 16px;
  /*height: 50px;*/
  /*line-height: 26px;*/
  /*color: #c0e3ff;*/
  /*background:#122648;*/
  background: white;
  color: black;

  border: 1px solid #4e637e;
  border-radius: 10px;

}

.marker-animation {
  /*width: 5vw;*/
  /*height: 5vh;*/
  /*margin-left: 3px;*/
  /*background-image: url("../assets/animation.gif");*/
  /*background-repeat: no-repeat;*/
  /*background-size: 100% 100%;*/
  display: flex;
  justify-content: center;
}

.marker::before,
.marker::after {
  position: absolute;
  bottom: -20px;
  left: calc(50% - 10px);
  display: block;
  font-size: 0;
  line-height: 0;
  /*border-color: #4e637e transparent transparent;*/
  border-color: #4e637e transparent transparent;

  border-style: solid;
  border-width: 10px;
  content: "";
}

.marker::after {
  bottom: -19px;
  /*border-color: #122648 transparent transparent;*/
  border-color: white transparent transparent;
}

.marker-title {
  border-bottom: 1px dashed #aaa;
  color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
}

.marker-title-empty {
  width: 15px;
}

.marker-title-content {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  flex: 1;
}

.type-img {
  margin-left: 8px;
  width: 20px;
  height: 20px;
}

.marker-text {
  /*margin-top: 5px;*/
  display: flex;
  justify-content: flex-start;

}

/*信息窗体*/
#info {
  width: 50vw;
  max-height: 80vh;
  overflow-y: auto;
  min-width: 250px;
  /*max-width: 950px;*/
  max-height: 80vh;
  cursor: auto;
}

/*信息窗体图片*/
.info-img {
  /*border: 2px solid red;*/
  /*max-width: 20vw;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-img img {
  border-radius: 4px;
}

/*信息窗体内容*/
.info-div {
  position: relative;
}

.info-content {
  margin: 5px 0;
  border-radius: 4px;
  /*background-color: rgba(255, 0, 0, 0.6);*/
  color: white;
  padding: 8px;

  overflow-y: auto;
}

.info-go {
  position: absolute;
  top: 2px;
  right: 10px;
  /*color: dodgerblue;*/
  /*font-size: 14px;*/
  /*background-color: white;*/
  /*padding: 4px 6px;*/
  /*border-radius: 40px;*/
  cursor: pointer;
}

/*搜索框 搜索结果列表*/
.search-div {
  position: absolute;
  top: 10px;
  right: 20px;
  min-width: 350px;
  z-index: 999;
}

.my-round {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #0081ff;
  margin-right: 4px;
}

.show-city {
  position: fixed;
  top: 10px;
  left: 60px;
  z-index: 999;
}

/*旗帜上的字*/
.qizhi-word {
  position: absolute;
  /*right: 64px;*/
  /*top: 15px;*/
  font-size: 14px;
  font-weight: 600;
  color: red;
  z-index: 999;
}

.jobid-list {
  position: absolute;
  top: 2px;
  left: -65px;
  color: white;
}

/*在win11的浏览器里要这样设置*/
.el-image__error, .el-image__inner, .el-image__placeholder {
  width: auto !important;
}
</style>
