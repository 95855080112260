<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ editableAuthName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ editableTabsTitle }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <!--卡片区域-->
    <div>
      <el-card>
        <!--搜索区-->
        <el-row>
          <el-col :span="10">

            <el-form :inline="false" class="searchDiv">

              <el-form-item label="地图标题">
                <el-input
                    clearable
                    placeholder="输入地图标题"
                    v-model="set_json.map_title"
                ></el-input>
              </el-form-item>

              <el-form-item label="标记标题(可用变量 [地区] )">
                <el-input
                    clearable
                    placeholder="输入标记标题"
                    v-model="set_json.marker_title"
                ></el-input>
              </el-form-item>

              <el-form-item label="默认地区">
                <el-input
                    clearable
                    placeholder="输入默认地区"
                    v-model="set_json.show_city"
                ></el-input>
              </el-form-item>

              <el-form-item label="旗帜显示的字">
                <el-input
                    clearable
                    placeholder="输入旗帜显示的字"
                    v-model="set_json.qizhi_word"
                ></el-input>
              </el-form-item>

              <el-form-item class="flex justify-center margin-top-xl">
                <el-button size="mini" round type="success" @click="onSaveEdit"
                >保存
                </el-button
                >
              </el-form-item>

            </el-form>
          </el-col>


        </el-row>
      </el-card>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      set_json: {
        'map_title': '',//'地图标题',
        'marker_title': '', //'标记标题',
        'show_city':'', //显示城市
        'qizhi_word':'',//旗帜显示的字
      }
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getData();
  },
  computed: {
    ...mapState(["editableTabsTitle", "editableAuthName"]),
  },
  //判断勾选
  watch: {},
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    //获取设置
    async getData() {

      const {code, msg, data} = await this.$post(`MapSet/getData`);
      console.log(msg);
      if (code !== 200) return;
      this.set_json = data || this.set_json;

    },

    async onSaveEdit() {

      let obj = {
        set_json: this.set_json,
      };

      this.showEditDlg = false;
      const {code, msg, data} = await this.$post(`MapSet/saveData`, obj);

      console.log(msg);
      if (code !== 200) return;

      this.$ok("保存成功");
      this.getData();
    },

  },
};
</script>

<style lang="less" scoped>

</style>
