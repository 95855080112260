<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ editableAuthName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ editableTabsTitle }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div id="" style="height: 10px"></div>
    <!--卡片区域-->
    <div>
      <el-card>
        <!--搜索区-->
        <el-row>
          <el-col :span="10">

            <el-form :inline="true" class="searchDiv">


              <el-form-item label="标题地区搜索">
                <el-input
                    clearable
                    placeholder="标题地区搜索"
                    v-model="show_title"
                ></el-input>
              </el-form-item>

              <el-form-item label="职位ID搜索">
                <el-input
                    clearable
                    placeholder="搜索职位ID"
                    v-model="job_id"
                ></el-input>
              </el-form-item>

              <!--              <el-date-picker-->
              <!--                  v-model="job_id"-->
              <!--                  type="job_id"-->
              <!--                  format="yyyy-MM"-->
              <!--                  value-format="yyyy-MM"-->
              <!--                  placeholder="搜索月份"-->
              <!--              >-->
              <!--              </el-date-picker>-->

            </el-form>
          </el-col>
          <el-col :span="2">
            <el-button size="mini" round type="success" @click="onSubmit"
            >搜索
            </el-button
            >
          </el-col>

          <el-col :span="2">
            <el-button size="mini" round type="info" @click="onReSet"
            >重置
            </el-button
            >
          </el-col>

          <el-col :span="2">
            <el-button size="mini" round type="primary" @click="onImport"
            >导入职位
            </el-button>
          </el-col>

<!--          <el-col :span="2">-->
<!--            <el-button size="mini" round type="warning" @click="onAdd"-->
<!--            >手工添加-->
<!--            </el-button>-->
<!--          </el-col>-->

        </el-row>
      </el-card>
      <!--表格区--->
      <el-table
          :data="tableData"
          :border="true"
          :height="getH()"
          stripe
          :resizable="true"
      >
        <el-table-column
            show-overflow-tooltip
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :width="item.width ? item.width : 100"
        >
          <template slot-scope="scope">
            <div v-if="item.type == 'array'">
              {{ getOptionVal(item.data, scope.row[item.prop]) }}
            </div>
            <!--操作类型数组-->
            <div
                v-else-if="scope.column['type'] == 'pic' ">
              <el-image
                  v-if="scope.row[scope.column['property']]"
                  :src="$getUrl(scope.row[scope.column['property']])"
                  fit="scale-down"
                  style="height: 100px"
              />
            </div>
            <div v-else>
              {{ scope.row[scope.column["property"]] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="caoz">
              <el-button type="text" size="small" @click="onEditItem(scope.row)"
              >编辑
              </el-button
              >

              <el-button
                  type="text"
                  size="small"
                  @click="onDelItem(scope.row)"
              >
                删除
              </el-button
              >

            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--添加dialog表格-->
    <!--编辑窗口-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="!editItem.id ? '新增' : '编辑'"
        :visible.sync="showEditDlg"
        width="300"
        :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
              class="myElCol"
              :span="11"
              v-for="(item, index) in propList"
              :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                  v-if="item.type == 'array'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
              <el-select
                  v-else-if="item.prop == 'power'"
                  v-model="editItem[item.prop]"
                  :collapse-tags="false"
                  multiple
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in powerList"
                    :key="index2"
                    :label="item2"
                    :value="item2"
                >
                </el-option>
              </el-select>

              <el-date-picker
                  v-else-if="item.type == 'time'"
                  v-model="editItem[item.prop]"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>

              <el-date-picker
                  v-else-if="item.type == 'job_id'"
                  v-model="editItem[item.prop]"
                  type="job_id"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  placeholder="选择月"
              >
              </el-date-picker>


              <el-input
                  v-else
                  :type="item.type == 'textarea' ? 'textarea' : 'text'"
                  :disabled="item.prop == 'id'"
                  :rows="2"
                  clearable
                  v-model="editItem[item.prop]"
              >
              </el-input>
              <MyUploadPlus
                  v-if="item.prop == 'set_val' && editItem['upload'] == true"
                  v-model="form.videoUrls"
                  accept="*"
                  :disabled="false"
                  @urlChange="onUrlChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer center">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
    <!--底部分页区-->
    <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>

    <!--导入职位-->
    <el-dialog
        v-if="showImportDlg"
        :close-on-click-modal="false"
        title="导入职位"
        :visible.sync="showImportDlg"
        width="80vw"
        :before-close="handleCloseImportDlg"
    >
    <zhao-job-list />
    </el-dialog>


  </div>
</template>

<script>
import {mapState} from "vuex";
import ZhaoJobList from "@/views/System/ZhaoJobList";
export default {
  components:{
    'zhao-job-list':ZhaoJobList
  },
  data() {
    return {
      form: {
        videoUrls: [],
      },
      isVideo: "*",
      isDetail: true,
      //设置列表
      tableData: [],

      //当前的页数
      pageNum: 1,
      //当前的页码显示的条数
      pageSize: 10,

      searchKey: "",
      is_bool: "",
      //总条数
      total: 0,
      /**
       *  `id` int(11) NOT NULL AUTO_INCREMENT,
       *   `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
       *   `job_id` int(11) NOT NULL DEFAULT '0' COMMENT '职位ID',
       *   `show_title` varchar(255) NOT NULL DEFAULT '' COMMENT '显示标题',
       *   `show_city` varchar(255) NOT NULL DEFAULT '' COMMENT '显示地区',
       *   `show_pic` varchar(255) NOT NULL DEFAULT '' COMMENT '显示图片',
       *   `show_video` varchar(255) NOT NULL DEFAULT '' COMMENT '显示视频',
       *   `bz` varchar(255) NOT NULL DEFAULT '' COMMENT '备注',
       */
      propList: [
        {
          prop: "id",
          label: "序号",
        },
        {
          prop: "add_time",
          label: "添加时间",
          type: 'time',
          width: 250,
        },
        {
          prop: "job_id",
          label: "职位ID",
        },
        {
          prop: "show_title",
          label: "显示标题",
          width: 250,
          type: 'textarea'
        },
        {
          prop: "show_city",
          label: "显示地区",
          width: 150
        },
        {
          prop: "show_video",
          label: "优先显示视频",
          width: 150,
          type: 'textarea'
        },
        {
          prop: "show_pic",
          label: "显示图片",
          width: 150,
          type: 'textarea'
        },

        {
          prop: "bz",
          label: "备注",
          width: 150,
        },
      ],
      show_title: '',
      job_id: '',

      //新增招聘系统设置
      editItem: {},
      //点击 添加 聊天记录列表弹框 显示与影藏
      showEditDlg: false,
      //显示导入窗体
      showImportDlg: false,

    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  computed: {
    ...mapState(["editableTabsTitle", "editableAuthName"]),
  },
  //判断勾选
  watch: {},
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    //获取设置列表
    async getList() {
      let obj = {
        page: this.pageNum,
        pageSize: this.pageSize,

        show_title: this.show_title,
        job_id: this.job_id,
      };
      const res = await this.$post(`MapJoblist/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;

      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.pageNum = newPage;
      //获取用户列表数据
      this.getList();
    },

    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },

    //新增或修改聊天记录管理
    onEditItem(item) {
      if (item) {
        for (let i in item) {
          this.$set(this.editItem, i, item[i])
          console.log(i, this.editItem[i]);
        }
      } else {
        for (let i in this.editItem) {
          this.$set(this.editItem, i, '')
        }
      }
      this.showEditDlg = true;
      console.log("this.editItem", this.editItem);
    },
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //
    async onSaveEdit() {

      let obj2 = {
        id: this.editItem.id,
        data: this.editItem,
      };

      this.showEditDlg = false;
      const res = await this.$post(`MapJoblist/saveData`, obj2);

      console.log("目赫尔", res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getList();
    },

    handleCloseDlg() {
      this.showEditDlg = false;
    },
    //链接改变了
    onUrlChange(e) {
      console.log("onUrlChange", e);
      this.editItem["set_val"] = e[0].url;
    },
    //点击删除弹出对话框是否真的删除
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久删除该用户信息
      const confirmResult = await this.$confirm(
          "确认删除此项吗?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj2 = {
        id: item.id,
      };
      console.log(obj2);
      const res = await this.$post(`MapJoblist/delItem`, obj2);
      console.log("确认删除", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    onReSet() {
      this.job_id = ''
      this.show_title = ''

      this.getList()
    },

    handleCloseImportDlg() {
      this.showImportDlg = false;
    },
    //导入职位
    onImport() {
      this.showImportDlg=true
    },

  },
};
</script>

<style lang="less" scoped>
.delDiv {
  margin-top: 12px;
  width: 50%;
  display: flex;
  align-items: center;

  .el-input,
  .el-button {
    margin-left: 10px;
  }
}

.fDiv {
  div {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-tag {
      flex-basis: 1;
      width: 120px !important;
      margin-right: 10px;
    }

    div {
      flex-grow: 1;
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
